<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Register-->
      <b-col
        lg="5"
        class="d-flex align-items-center auth-bg px-2"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1 text-center"
          >
            <b-link class="">
              <img
                :src="logoUrl"
                height="100"
                weight="80"
                alt=""
                srcset=""
              >
            </b-link>
            <br>
          </b-card-title>
          <b-card-text class="mb-1">
            {{ $t('register.welcomeSubtitle') }}
          </b-card-text>

          <form-wizard
            ref="registerWizard"
            color="#126e51"
            :title="null"
            :subtitle="null"
            :hide-buttons="true"
            step-size="xs"
            class="mb-0"
          >

            <!-- accoint details tab -->
            <tab-content
              title=""
            >
              <b-row>
                <b-col sm="12">
                  <b-form-group
                    :label="$t('form.name')"
                    label-for="register-name"
                  >
                    <b-form-input
                      id="register-name"
                      v-model="$v.user.name.$model"
                      :formatter="formatter"
                      :class="{
                        'is-invalid': $v.user.name.$error
                      }"
                      name="register-name"
                    />
                    <div
                      v-if="$v.user.name.$error"
                      class="invalid-feedback"
                    >
                      <span
                        v-if="!$v.user.name.required"
                      >{{ $t('errors.required') }}</span>
                    </div>
                  </b-form-group>
                </b-col>

                <b-col sm="12">
                  <b-form-group
                    :label="$t('form.surname')"
                    label-for="register-surname"
                  >
                    <b-form-input
                      id="register-surname"
                      v-model="$v.user.surname.$model"
                      :formatter="formatter"
                      :class="{
                        'is-invalid': $v.user.surname.$error
                      }"
                      name="register-surname"
                    />
                    <div
                      v-if="$v.user.surname.$error"
                      class="invalid-feedback"
                    >
                      <span
                        v-if="!$v.user.surname.required"
                      >{{ $t('errors.required') }}</span>
                    </div>
                  </b-form-group>
                </b-col>

                <b-col sm="12">
                  <b-form-group
                    id="phone"
                    :label="$t('form.phone') + '*'"
                    label-for="register-phone"
                  >
                    <vue-phone-number-input
                      v-model.trim="phone"
                      name="register-phone"
                      size="sm"
                      valid-color="#126e51"
                      error-color="red"
                      :dark="dark"
                      dark-color="transparent"
                      color="#126e51"
                      :fetch-country="true"
                      :border-radius="4"
                      :required="true"
                      @update="update($event)"
                      @phone-number-blur="checkPhone($event)"
                    />
                    <span
                      v-if="phoneExist === 1"
                      style="font-size: 12px"
                      class="text-danger"
                    >
                      {{ $t('register.phone-used') }}
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-button
                    variant="outline-primary"
                    class="float-right"
                    @click="stepOne"
                  >
                    {{ $t('form.next') }}
                  </b-button>
                </b-col>
              </b-row>
            </tab-content>

            <!-- personal details tab -->
            <tab-content
              title=""
            >
              <b-row>
                <b-col sm="12">
                  <b-form-group
                    :label="$t('form.email')"
                    label-for="register-email"
                  >
                    <b-form-input
                      id="register-email"
                      v-model="$v.user.email.$model"
                      :formatter="lower"
                      :class="{
                        'is-invalid': $v.user.email.$error || emailExist === 1
                      }"
                      name="register-email"
                      placeholder="toto@example.com"
                      @blur="checkEmail"
                    />
                    <div
                      v-if="$v.user.email.$error"
                      class="invalid-feedback"
                    >
                      <span
                        v-if="!$v.user.email.required"
                      >{{ $t('errors.required') }}</span>
                      <span
                        v-if="!$v.user.email.email"
                      >{{ $t('errors.email') }}</span>
                    </div>
                    <div
                      v-if="emailExist === 1"
                      class="invalid-feedback"
                    >
                      {{ $t('register.email-used') }}
                    </div>
                  </b-form-group>
                </b-col>

                <b-col sm="12">
                  <b-form-group
                    :label="$t('form.password')"
                    label-for="register-password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="{ 'is-invalid': $v.user.password.$error }"
                    >
                      <b-form-input
                        id="register-password"
                        v-model="$v.user.password.$model"
                        :class="{
                          'is-invalid': $v.user.password.$error
                        }"
                        name="register-password"
                        :type="passwordFieldType"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                      <div
                        v-if="$v.user.password.$error"
                        class="invalid-feedback"
                      >
                        <span
                          v-if="!$v.user.password.required"
                        >{{ $t('errors.required') }}</span>
                        <span
                          v-if="!$v.user.password.password"
                        >{{ $t('errors.password') }}</span>
                      </div>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col sm="12">
                  <b-form-group
                    :label="$t('form.confirm-password')"
                    label-for="register-confirm_password"
                  >
                    <b-form-input
                      id="register-confirm_password"
                      v-model="$v.user.confirm_password.$model"
                      :class="{
                        'is-invalid': $v.user.confirm_password.$error
                      }"
                      name="register-confirm_password"
                      type="password"
                    />
                    <div
                      v-if="$v.user.confirm_password.$error"
                      class="invalid-feedback"
                    >
                      <span
                        v-if="!$v.user.confirm_password.sameAs"
                      >{{ $t('errors.confirm-password') }}</span>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-button
                    variant="outline-primary"
                    class="float-left"
                    :disabled="process"
                    @click="$refs.registerWizard.prevTab()"
                  >
                    {{ $t('form.prev') }}
                  </b-button>
                  <b-button
                    variant="outline-primary"
                    class="float-right"
                    :disabled="process"
                    @click="stepTwo"
                  >
                    <b-spinner
                      v-if="process"
                      small
                    />
                    <span v-show="!process">{{ $t('register.sign-up') }}</span>
                  </b-button>
                </b-col>
              </b-row>
            </tab-content>
          </form-wizard>

          <b-card-text class="text-center">
            <span>{{ $t('register.already-account') }} </span>
            <b-link :to="{name:'login'}">
              <span>&nbsp;{{ $t('login.sign-in') }}</span>
            </b-link>
          </b-card-text>

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text text-primary font-weight-bold">
              Godwin Multiservice
            </div>
          </div>
        </b-col>
      </b-col>
      <!-- /Register-->

      <!-- Right Text-->
      <b-col
        lg="7"
        class="d-none d-lg-flex align-items-center"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Right Text-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { mapActions, mapGetters } from 'vuex'
import { FormWizard, TabContent } from 'vue-form-wizard'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  required, email, helpers, sameAs,
} from 'vuelidate/lib/validators'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'

const { getName } = require('country-list')

export default {
  components: {
    // validations
    FormWizard,
    TabContent,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      countries: ['BJ', 'BF', 'CM', 'CI', 'GN', 'ML', 'SN', 'TG'],
      phone: null,
      phoneExist: null,
      emailExist: null,
      user: {
        name: null,
        surname: null,
        phone: null,
        country: null,
        email: null,
        password: null,
        confirm_password: null,
      },
      sideImg: require('@/assets/images/auth/register.svg'),
      logoImg: require('@/assets/images/logo/logo-sombre.svg'),
    }
  },
  validations: {
    user: {
      name: {
        required,
      },
      surname: {
        required,
      },
      phone: {
        required,
      },
      country: {
        required,
      },
      email: {
        email,
        required,
      },
      password: {
        required,
        password: helpers.regex(
          'password',
          /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{12,}$/,
        ),
      },
      confirm_password: {
        sameAs: sameAs('password'),
      },
    },
  },
  computed: {
    ...mapGetters({
      process: 'registerProcess',
      error: 'registerError',
      success: 'registerSuccess',
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/auth/register.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    logoUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.logoImg = require('@/assets/images/logo/logo-sombre.svg')
        return this.logoImg
      }
      return this.logoImg
    },
    dark() {
      if (window.localStorage.getItem('vuexy-skin') === 'dark') return true
      return false
    },
  },
  watch: {
    success(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('register.registration'),
            text: this.$t('register.register-success'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'login' })
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('register.registration'),
            text: this.$t('register.register-error'),
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  methods: {
    ...mapActions(['registerUser']),
    formatter(value) {
      return value.toUpperCase()
    },
    lower(value) {
      return value.toLowerCase()
    },
    async checkPhone() {
      this.phoneExist = null
      this.$v.user.phone.$touch()
      if (this.$v.user.phone.$error) return
      const data = JSON.stringify({
        phone: this.user.phone,
      })
      this.phoneExist = await this.$http({
        method: 'post',
        url: '/check-user-by-phone',
        data,
      })
        .then(res => {
          if (res.data === 'NOT_EXIST') {
            return 0
          }
          return 1
        })
        .catch(() => {})
    },
    async checkEmail() {
      this.emailExist = null
      this.$v.user.email.$touch()
      if (this.$v.user.email.$error) return
      const data = JSON.stringify({
        email: this.user.email,
      })
      this.emailExist = await this.$http({
        method: 'post',
        url: '/check-user-by-email',
        data,
      })
        .then(res => {
          if (res.data === 'NOT_EXIST') {
            return 0
          }
          return 1
        })
        .catch(() => {})
    },
    update(event) {
      if (event) {
        this.user.phone = event.formattedNumber
        this.user.country = getName(event.countryCode)
      }
    },
    stepOne() {
      this.$v.user.name.$touch()
      this.$v.user.surname.$touch()
      this.$v.user.phone.$touch()
      this.$v.user.country.$touch()
      if ((this.phoneExist === 1 || this.phoneExist == null) || this.$v.user.name.$error || this.$v.user.country.$error || this.$v.user.surname.$error || this.$v.user.phone.$error) return
      this.$refs.registerWizard.nextTab()
    },
    stepTwo() {
      this.$v.user.email.$touch()
      this.$v.user.password.$touch()
      this.$v.user.confirm_password.$touch()
      if ((this.emailExist === 1 || this.emailExist == null) || this.$v.user.email.$error || this.$v.user.password.$error || this.$v.user.confirm_password.$error || this.emailExist !== 0) return
      this.registerUser({
        name: this.user.name,
        surname: this.user.surname,
        phone: this.user.phone,
        country: this.user.country,
        email: this.user.email,
        password: this.user.password,
        password_confirmation: this.user.confirm_password,
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
