var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2",attrs:{"lg":"5"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1 text-center",attrs:{"title-tag":"h2"}},[_c('b-link',{},[_c('img',{attrs:{"src":_vm.logoUrl,"height":"100","weight":"80","alt":"","srcset":""}})]),_c('br')],1),_c('b-card-text',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t('register.welcomeSubtitle'))+" ")]),_c('form-wizard',{ref:"registerWizard",staticClass:"mb-0",attrs:{"color":"#126e51","title":null,"subtitle":null,"hide-buttons":true,"step-size":"xs"}},[_c('tab-content',{attrs:{"title":""}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.name'),"label-for":"register-name"}},[_c('b-form-input',{class:{
                      'is-invalid': _vm.$v.user.name.$error
                    },attrs:{"id":"register-name","formatter":_vm.formatter,"name":"register-name"},model:{value:(_vm.$v.user.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.user.name, "$model", $$v)},expression:"$v.user.name.$model"}}),(_vm.$v.user.name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.name.required)?_c('span',[_vm._v(_vm._s(_vm.$t('errors.required')))]):_vm._e()]):_vm._e()],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.surname'),"label-for":"register-surname"}},[_c('b-form-input',{class:{
                      'is-invalid': _vm.$v.user.surname.$error
                    },attrs:{"id":"register-surname","formatter":_vm.formatter,"name":"register-surname"},model:{value:(_vm.$v.user.surname.$model),callback:function ($$v) {_vm.$set(_vm.$v.user.surname, "$model", $$v)},expression:"$v.user.surname.$model"}}),(_vm.$v.user.surname.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.surname.required)?_c('span',[_vm._v(_vm._s(_vm.$t('errors.required')))]):_vm._e()]):_vm._e()],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"id":"phone","label":_vm.$t('form.phone') + '*',"label-for":"register-phone"}},[_c('vue-phone-number-input',{attrs:{"name":"register-phone","size":"sm","valid-color":"#126e51","error-color":"red","dark":_vm.dark,"dark-color":"transparent","color":"#126e51","fetch-country":true,"border-radius":4,"required":true},on:{"update":function($event){return _vm.update($event)},"phone-number-blur":function($event){return _vm.checkPhone($event)}},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"phone"}}),(_vm.phoneExist === 1)?_c('span',{staticClass:"text-danger",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.$t('register.phone-used'))+" ")]):_vm._e()],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-button',{staticClass:"float-right",attrs:{"variant":"outline-primary"},on:{"click":_vm.stepOne}},[_vm._v(" "+_vm._s(_vm.$t('form.next'))+" ")])],1)],1)],1),_c('tab-content',{attrs:{"title":""}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.email'),"label-for":"register-email"}},[_c('b-form-input',{class:{
                      'is-invalid': _vm.$v.user.email.$error || _vm.emailExist === 1
                    },attrs:{"id":"register-email","formatter":_vm.lower,"name":"register-email","placeholder":"toto@example.com"},on:{"blur":_vm.checkEmail},model:{value:(_vm.$v.user.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.user.email, "$model", $$v)},expression:"$v.user.email.$model"}}),(_vm.$v.user.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.email.required)?_c('span',[_vm._v(_vm._s(_vm.$t('errors.required')))]):_vm._e(),(!_vm.$v.user.email.email)?_c('span',[_vm._v(_vm._s(_vm.$t('errors.email')))]):_vm._e()]):_vm._e(),(_vm.emailExist === 1)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.$t('register.email-used'))+" ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.password'),"label-for":"register-password"}},[_c('b-input-group',{staticClass:"input-group-merge",class:{ 'is-invalid': _vm.$v.user.password.$error }},[_c('b-form-input',{class:{
                        'is-invalid': _vm.$v.user.password.$error
                      },attrs:{"id":"register-password","name":"register-password","type":_vm.passwordFieldType},model:{value:(_vm.$v.user.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.user.password, "$model", $$v)},expression:"$v.user.password.$model"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1),(_vm.$v.user.password.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.password.required)?_c('span',[_vm._v(_vm._s(_vm.$t('errors.required')))]):_vm._e(),(!_vm.$v.user.password.password)?_c('span',[_vm._v(_vm._s(_vm.$t('errors.password')))]):_vm._e()]):_vm._e()],1)],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.confirm-password'),"label-for":"register-confirm_password"}},[_c('b-form-input',{class:{
                      'is-invalid': _vm.$v.user.confirm_password.$error
                    },attrs:{"id":"register-confirm_password","name":"register-confirm_password","type":"password"},model:{value:(_vm.$v.user.confirm_password.$model),callback:function ($$v) {_vm.$set(_vm.$v.user.confirm_password, "$model", $$v)},expression:"$v.user.confirm_password.$model"}}),(_vm.$v.user.confirm_password.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.user.confirm_password.sameAs)?_c('span',[_vm._v(_vm._s(_vm.$t('errors.confirm-password')))]):_vm._e()]):_vm._e()],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-button',{staticClass:"float-left",attrs:{"variant":"outline-primary","disabled":_vm.process},on:{"click":function($event){return _vm.$refs.registerWizard.prevTab()}}},[_vm._v(" "+_vm._s(_vm.$t('form.prev'))+" ")]),_c('b-button',{staticClass:"float-right",attrs:{"variant":"outline-primary","disabled":_vm.process},on:{"click":_vm.stepTwo}},[(_vm.process)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.process),expression:"!process"}]},[_vm._v(_vm._s(_vm.$t('register.sign-up')))])],1)],1)],1)],1)],1),_c('b-card-text',{staticClass:"text-center"},[_c('span',[_vm._v(_vm._s(_vm.$t('register.already-account'))+" ")]),_c('b-link',{attrs:{"to":{name:'login'}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('login.sign-in')))])])],1),_c('div',{staticClass:"divider my-2"},[_c('div',{staticClass:"divider-text text-primary font-weight-bold"},[_vm._v(" Godwin Multiservice ")])])],1)],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center",attrs:{"lg":"7"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }